// Semantic.gs
$column-width: 60px;
$gutter-width: 20px;
$columns: 12;

@function gridsystem-width($columns: $columns) {
	@return $column-width * $columns + $gutter-width * $columns; }

// $total-width: gridsystem-width($columns)
$total-width: 100%;

@mixin cf {
	&:after {
		content: "";
		display: table;
		clear: both; } }

@mixin row($columns: $columns) {
	display: block;
	width: $total-width * ($gutter-width + gridsystem-width($columns)) / gridsystem-width($columns);
	margin: 0 ($total-width * $gutter-width * 0.5 / gridsystem-width($columns) * -1);
	@include cf; }

@mixin col($x, $columns: $columns) {
	display: inline;
	float: left;
	width: $total-width * (($gutter-width + $column-width) * $x - $gutter-width) / gridsystem-width($columns);
	margin: 0 ($total-width * $gutter-width * 0.5 / gridsystem-width($columns)); }

@mixin push($offset: 1) {
	margin-left: $total-width * ($gutter-width + $column-width) * $offset / gridsystem-width($columns) + $total-width * $gutter-width * 0.5 / gridsystem-width($columns); }

@mixin pull($offset: 1) {
	margin-right: $total-width * ($gutter-width + $column-width) * $offset / gridsystem-width($columns) + $total-width * $gutter-width * 0.5 / gridsystem-width($columns); }
