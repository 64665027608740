footer {
  position: fixed;
  // z-index: 101
  bottom: -85px;
  width: 100%;
  padding-bottom: 20px;
  transition: bottom 0.12s ease 0s;

  .footer-menu {
    margin: 0; }

  .footer-links {
    margin: 0;
    text-transform: uppercase;
    float: left;
    font-size: 0.8em;
    width: 25%;

    a {
      width: 100%;
      display: inline-block;
      text-decoration: none;
      padding-top: 20px;
      padding-bottom: 20px;
      color: $cm-white;
      font-family: 'Montserrat', sans-serif;
      transition: color 0.1s;
      transition: background-color 0.2s;
      text-align: center;

      &:hover {
        background-color: transparentize($cm-white, 0.8); }

      @media screen and (max-width: 768px) {
        font-size: 10px; } } }

  .footer--trigger {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    color: $cm-white;
    padding-top: 10px;
    padding-bottom: 20px;
    text-decoration: none;
    font-size: 0.8em;

    &:hover {
      text-decoration: none; }

    i {
      margin-left: 10px; } } }

.top_space {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: auto; }

.gotop {
  position: absolute;
  z-index: 999;
  right: 10px;
  bottom: -22px;
  width: 45px;
  height: 45px;
  display: none;
  line-height: 45px;
  border-radius: 50px;
  text-align: center;
  background-color: #FFF;
  color: $cm-col-primary;
  font-weight: 700;
  box-shadow: 0 2px 5px transparentize($cm-black, 0.7); }

.warning_contact {
  width: 100%;
  max-width: 570px;
  min-width: 200px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  padding: 5px 25px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px darken($cm-warning, 2%);
  border-radius: 3px;

  text-align: center;
  color: darken($cm-warning, 2%);
  font-size: 1em;
  text-align: left;

  a {
    color: darken($cm-warning, 2%);
    text-decoration: underline; } }
