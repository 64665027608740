.contact {
	margin-bottom: 100px; }
.form-box {
	@include box-sizing(border-box);
	padding: 10px;
	// float: left
	width: 60%;
	margin-right: auto;
	margin-left: auto;
	@media only screen and (max-width: 1024px) {
		width: 70%; }
	@media only screen and (max-width: 768px) {
		width: 100%; }

	.link {
		text-decoration: underline;
		color: $cm-col-primary;
		&:visited {
			color: $cm-col-primary; }
		&:hover {
			color: darken($cm-col-primary, 20%);
			text-decoration: underline; }
		i {
			margin-left: 5px;
			color: $cm-col-third; } }

	.send-area {
		width: 100%;
		text-align: center;
		a i {
			margin-left: 8px; } }

	.cta {
		min-width: 200px;
		&.cta-sm {
			min-width: 0;
			float: right; } }

	.tabs {
		@include clearfix;
		margin: 0px;
		padding: 0px;
		list-style: none;
		// border-bottom: 1px solid $cm-col-primary

		li {
			@include box-sizing(border-box);
			color: $cm-black;
			width: 33.333%;
			float: left;
			display: block;
			text-align: center;
			padding: 10px 5px;
			cursor: pointer;
			transition: all 0.2s ease 0s;
			border-radius: 3px;
			font-size: 0.9rem;
			border: 1px solid transparent;
			&:hover {
				color: $cm-col-primary; }
			&.current {
				border: 1px solid $cm-col-primary;
				color: darken($cm-col-primary, 10%); }
			&.redlink-gradient-izq {
				color: $cm-white; }
			@media screen and (max-width: 640px) {
				font-size: 0.7rem;
				i {
					display: none; } } } } }

.phones-trigger {
	display: block;
	margin-bottom: 20px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
	padding: 30px; }

.phones-trigger-hide {
	// display: none
	display: block;
	padding-top: 20px;
	padding-bottom: 10px;
	font-size: 0.8rem;
	color: $cm-col-primary !important;
	font-weight: 700; }

.phones {
	position: relative;
	display: none;
	@include box-sizing(border-box);
	padding: 20px;
	background-size: cover;
	background-blend-mode: multiply;
	.velo {
		display: block;
		width: 100%;
		height: 100%;
		background-color: red; }
	.title {
		color: $cm-white;
		margin: 0;
		padding-top: 5px;
		padding-bottom: 20px; }

	.util-phones {
		&--box {
			@include box-sizing(border-box);
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 15px;
			background-color: transparentize($cm-white, 0.2);
			border-radius: 3px;
			&:hover {
				box-shadow: 0 3px 5px transparentize($cm-black, 0.8); }

			.accent {
				display: block;
				float: left;
				margin-right: 10px;
				border-radius: 3px;
				padding: 1px 3px;
				background-color: $cm-error;
				font-size: .75rem;
				color: $cm-white; }
			p {
				margin-top: 5px;
				word-break: keep-all; } } } }
.featherlight {
	.featherlight-content {
		margin-top: 7px; }

	.featherlight-close-icon {
		background-color: #fff !important;
		border-radius: 50px;
		margin-right: 8px;
		margin-top: 8px; } }

.tab--content, {
	display: none;
	margin-top: 30px;

	&.current {
		display: inherit; }

	&--info {
		.titles {
			color: $cm-col-primary;
			margin: 0;
			font-weight: 700; }

		@media screen and (max-width: 768px) {
			padding: 0; } } }

.send-msg {
	display: none;
	position: relative;
	border-radius: 3px;
	text-align: center;
	color: $cm-white;
	margin-bottom: 10px;
	.title {
		margin: 0;
		@include box-sizing(border-box);
		padding: 15px;
		font-size: 1rem; }
	&.success {
		background-color: $cm-success; }
	&.error {
		background-color: $cm-error; }
	.close {
		position: absolute;
		top: 0px;
		right: 8px;
		font-size: 1.3rem;
		cursor: pointer;
		opacity: 0.5;
		&:hover {
			opacity: 1; } } }

// DEFAULT STYLE
.field,
.autocomplete-container-7a26d {
	@include box-sizing(border-box);
	padding: 10px;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 3px;
	border: 2px;
	border-style: solid;
	border-color: darken($cm-white, 10%);
	background-color: #FFF;
	&:hover {
		border-color: darken($cm-white, 20%); }
	&:focus {
		// background-color: darken($cm-white, 5%)
		box-shadow: inset 0 2px 2px 2px rgba($cm-black, 0.1);
		border-color: $cm-col-primary; }
	&.required,
	&.requiredemail {
		&.error {
			border-color: $cm-error; }
		&.warning {
			border-color: $cm-warning; } } }

.formcv {
	width: 100%;
	float: left; }
