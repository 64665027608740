//first you can reset all css
// @import utils/reset
@import "utils/variables";
//include normalize
@import "utils/normalize";
//include bourbon for mixins and extends
@import "bourbon/bourbon";
//global elements of the project
@import "utils/utils";
//particular elements of your project
@import "layout/layout";
