.social-links {
  @include clearfix;
  position: fixed;
  z-index: 999;
  top: 78px;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0 2px 10px transparentize(#000, 0.8);
  border-radius: 0 0 0 10px;
  transition: transform 0.2s ease, top 0.4s ease;

  @media screen and (max-width: 640px) {
    top: 53px; }

  &.is-scroll {
    top: 53px; }

  &__item {
    @extend .air;
    padding: 15px;
    color: $cm-col-primary;

    @media screen and (max-width: 640px) {
      padding: 10px; }

    &_trigger {
      color: gray;
      cursor: pointer;
      border-right: 1px solid transparentize(#000, 0.9); } } }

.external-links {
  @include clearfix;
  position: fixed;
  z-index: 999;
  right: 0;
  transition: transform 0.2s ease;

  &--container {
    @include clearfix;
    position: relative;
    // min-width: 120px
    // background-color: #FFF
    // box-shadow: 0 0 10px transparentize($cm-black, 0.8)

    @media screen and (min-width: 769px) {
      width: 150px; }
    @media screen and (max-width: 768px) {
      top: auto; }

    .nav__bgscroll {
      position: absolute;
      display: block;
      width: 60%;
      right: 0;
      background-size: 100% 100%;
      border-radius: 0 0 0 20px;
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;

      &--top {
        top: 0;
        height: 15px;
        background-image: url("../img/nav__bgscroll--top.png"); }

      &--bottom {
        bottom: 0;
        height: 25px;
        background-image: url("../img/nav__bgscroll--bottom.png"); }

      @media screen and (min-width: 769px) {
        width: 65%; } }

    ul {
      margin: 0;
      // width: 60%
      max-height: 75vh;
      height: auto;
      overflow-y: scroll;
      overflow-x: hidden;
      float: left;
      background-color: $cm-white;
      border-radius: 0 0 0 20px;
      text-align: center;
      box-shadow: 0 0 10px transparentize($cm-black, 0.8);
      // padding-bottom: 25px

      @media screen and (min-width: 769px) {
        width: 65%; }
      @media screen and (max-width: 400px) {
        max-height: 80vh; }
      @media screen and (max-width: 320px) {
        max-height: 70vh; }

      li {
        text-align: center;
        &:last-child {
          margin-bottom: 20px; }

        a {
          transition: background-color 0.35s ease 0s;
          display: block;
          text-decoration: none;
          margin: 3px;
          padding-top: 15px;
          padding-bottom: 8px;
          border-radius: 3px;

          &:hover {
            // background-color: darken(#fff, 5%)
            background-color: lighten(#00aced, 50%);
            .link_description {
              color: #00aced; } } }

        img {
          width: 100%;
          height: auto;
          max-width: 45px; }

        .external_link {
          display: block;
          color: grey;
          transition: color 0.1s ease 0s;
          font-size: 1.2em;
          margin-right: 15px;
          margin-left: 15px;
          margin-bottom: 5px;
          background-image: url("../img/external-links.png");

          &:hover {
            color: $cm-col-secondary; } }

        .link_description {
          display: block;
          font-size: 0.6rem;
          text-align: center;
          color: lighten($cm-black, 50%);
          line-height: 12px;
          transition: all 0.1s ease 0s; }

        .el-1 {
          width: 45px;
          height: 45px;
          background-position: center 0; }
        .el-2 {
          width: 45px;
          height: 45px;
          background-position: center -45px; }
        .el-3 {
          width: 45px;
          height: 58px;
          background-position: center -90px; }
        .el-4 {
          width: 45px;
          height: 39px;
          background-position: center -148px; } } } }

  &--trigger {
    float: left;
    width: 41px;
    background-color: $cm-white;
    color: gray;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 10px transparentize($cm-black, 0.8);
    transition: color 0.2s ease 0s;

    @media screen and (min-width: 769px) {
      width: 51px; }

    @media only screen and (max-width: 768px) {
      padding-top: 10px;
      padding-bottom: 10px; } } }
