//neutral colors
$cm-black: #000000;
$cm-white: #ffffff;
$cm-error: #E74E40;
$cm-success: #42BD41;
$cm-warning: #FF9700;

//brand colors
$cm-brand-primary: #077652;
$cm-brand-secondary: #FACE00;
$cm-col-primary: #46a6c3;
$cm-col-secondary: #70c6ab;
$cm-col-third: #00ACED;

//HUE
$cm-random-00: hsl(150, 50, 50);

$iphone: 320px;
$phone: 480px;
$landscape: 640px;
$tablet: 768px;
$desktop: 1170px;

// media queries

@mixin MQ($canvas) {
	@if $canvas == iphone {
		@media only screen and (max-width: $iphone) {
			@content; } }
	@else if $canvas == phone {
		@media only screen and (max-width: $phone) {
			@content; } }
	@else if $canvas == landscape {
		@media only screen and (max-width: $landscape) {
			@content; } }
	@else if $canvas == tablet {
		@media only screen and (max-width: $tablet) {
			@content; } }
	@else if $canvas == desktop {
		@media only screen and (max-width: $desktop) {
			@content; } } }
