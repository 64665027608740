.services {
	@include clearfix;
	&--box {
		@include clearfix;
		float: left;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
		padding: 8px;

		h3 {
			margin: 0;
			text-align: center;
			color: $cm-white;
			font-weight: 400;
			font-size: 1.4rem; }

		p {
			margin-top: 40px;
			margin-bottom: 40px;
			line-height: 1.5rem;
			font-weight: 400; }

		p, a {
			text-align: center;
			color: $cm-white; }

		&-container {
			position: relative;
			background-position: center center;
			background-repeat: no-repeat;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			background-size: cover;
			overflow: hidden;

			// &.bg-01
			/// background-image: url(../img/band01.jpg)
			// &.bg-03
			/// background-image: url(../img/band02.jpg)
			// &.bg-02
			/// background-image: url(../img/bg.png)
			&:hover {
				.services--box-content {
					transform: translate(0,0); } } }

		&-content {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			-ms-box-sizing: border-box;
			box-sizing: border-box;
			padding: 30px;
			transform: translate(0, 74%);
			transition: transform 0.3s ease-in-out 0.1s;
			@media only screen and (max-width: 768px) {
				transform: translate(0, 0); }

			.more-info {
				display: block;
				margin-right: auto;
				margin-left: auto; } }

		&.three {
			width: 33.333%;
			@media only screen and (max-width: 768px) {
				width: 100%; } }

		&.four {
			width: 25%;
			@media only screen and (max-width: 768px) {
				width: 50%; }
			@media only screen and (max-width: 480px) {
				width: 100%; }

			h3 {
				font-size: 1rem;
				font-weight: 700; }

			p {
				margin-top: 50px;
				margin-bottom: 10px;
				line-height: 1rem; }

			.services--box-content {
				padding: 20px; } } } }
