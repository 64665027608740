.cta {
	display: inline-block;
	cursor: pointer;
	margin-bottom: 20px;
	margin-top: 20px;
	padding: 0.6rem 1rem;
	background-color: #ccc;
	border-radius: 3px;
	text-decoration: none;
	color: $cm-black;
	font-size: 1.1rem;
	text-align: center;
	font-weight: 700;
	@include transition(all 0.2s ease-in-out);
	&:hover {
		text-decoration: none;
		box-shadow: inset 0 0 0 3px rgba($cm-black, 0.2); }
	&:active {
		box-shadow: inset 0 0 0 5px rgba($cm-black, 0.2); }

	&.cta-sm {
		min-width: none;
		padding: 0.4rem;
		font-weight: 400;
		font-size: 0.8rem; } }

.cta-primary {
	background-color: $cm-col-third;
	color: $cm-white;
	&:hover {
		background-color: darken($cm-col-third, 5%); } }

.cta-secondary {
	background-color: $cm-brand-secondary;
	color: $cm-white;
	&:hover {
		background-color: darken($cm-brand-secondary, 5%); } }

.cta-dark {
	background-color: $cm-black;
	color: $cm-white;
	&:hover {
		background-color: lighten($cm-black, 20%); } }

.cta-noborder {
	border: none; }

.cta-square {
	width: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	display: block;
	text-align: center;
	font-size: 1.2em;
	border: 2px solid blue;
	&.light {
		border: 2px solid $cm-white;
		color: $cm-white;
		text-shadow: 0 1px 3px rgba($cm-black, 0.5);
		box-shadow: 0 1px 3px rgba($cm-black, 0.3); }
	&.dark {
		border: 2px solid $cm-black;
		color: $cm-black; }
	&:hover {
		&.light {
			background-color: $cm-white;
			// color: $cm-brand-primary
			color: $cm-black; }
		&.dark {
			background-color: $cm-black;
			color: $cm-white; } } }

.next-area {
	position: absolute;
	width: 100%;
	bottom: 60px;
	@include MQ(landscape) {
		display: none; }
	@include MQ(phone) {
		display: block;
		bottom: 30px; } }

.cta-next {
	margin-right: auto;
	margin-left: auto; }

.social {
	&.right {
		a {
			float: right;
			margin-left: 15px;
			@include MQ(tablet) {
				margin-left: 8px; }
			@include MQ(phone) {
				margin-left: 4px; } } }
	&.left {
		a {
			margin-right: 15px;
			float: left;
			@include MQ(tablet) {
				margin-right: 8px; } } } }

.social-footer {
	.cta-square {
		margin-bottom: 15px;
		&:hover {
			color: $cm-brand-secondary; } } }

