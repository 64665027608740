.hero {
  position: relative; }

.hero_result {
  display: block;
  padding-top: 150px; }

.slider-pager {
  z-index: 99 !important; }

.blogSummary {
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  height: 220px;
  padding-bottom: 20px;
  max-height: 230px;
  box-shadow: 0 -1px 3px transparentize(#000, 0.9);

  @media screen and (max-width: 1366px) {
    height: auto; }

  @media screen and (max-width: 640px) {
    height: auto;
    padding-bottom: 0; }

  &.is-hidden {
    display: none; }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;

    @media screen and (max-width: 640px) {
      display: none; }

    &Head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid transparentize(lightgray, 0.5); } }

  &__head {
    margin-left: 30px;
    color: gray;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;

    &_title {
      @media screen and (max-width: 640px) {
        display: none; } }

    &_link {
      color: $cm-col-primary;
      text-align: right;

      @media screen and (max-width: 640px) {
        text-align: center;
        margin-left: 0; } } }

  &__article {
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px 30px;
    width: 50%;

    p {
      width: 100%;
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.9em;
      color: gray; }

    a {
      display: inline-block;
      width: 100%;
      font-size: 1.4em;
      color: $cm-col-secondary;

      @media screen and (max-width: 1366px) {
        font-size: 1.1em; } }


    &_blog {
      display: inline-block;
      width: 150px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      border-radius: 5px;
      box-shadow: inset 0 0 0 1px lightgray;
      font-size: 1.3em;
      text-align: left;
      text-decoration: none;
      color: $cm-col-secondary;
      transition: box-shadow 0.2s ease;

      .fa {
        margin-bottom: 10px; }

      &:hover {
        text-decoration: none;
        box-shadow: inset 0 0 0 3px $cm-col-secondary; } } } }



.scroll_down {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparentize(#000, 0.7);
  // background-color: transparentize($cm-col-primary, 0.5)

  a {
    display: block;
    padding-top: 20px;
    padding-bottom: 55px;
    font-size: 2rem;
    color: #fff;
    text-align: center;
    transition: all 0.2s ease 0s;
    // text-shadow: 0 3px 3px transparentize(#000, 0.8)
    @media only screen and (max-width:1366px) {
      padding-top: 10px;
      padding-bottom: 45px; }

    &:hover {
      padding-bottom: 45px;
 } } }      // color: $cm-col-secondary


.band {
  position: relative;
  width: 100%;
  height: 250px;
  box-shadow: inset 0 -2px 4px transparentize($cm-black, 0.8);
  background-image: url(../img/backgrounds/default.png);
  background-position: center top;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  @media only screen and (max-width: 640px) {
    height: 150px; } }

.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;

  .go-left,
  .go-right {
    display: block;
    width: 40px;
    height: 40px;
    // padding: 10px
    font-size: 30px;
    color: $cm-brand-primary;
    position: absolute;
    text-align: center;
    top: 50%;
    z-index: 400;
    cursor: pointer;
    margin-top: -12.5px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    background-color: transparentize($cm-white, 0.2);
    -webkit-transition: box-shadow 0.5s ease;
    -moz-transition: box-shadow 0.5s ease;
    -o-transition: box-shadow 0.5s ease;
    transition: box-shadow 0.5s ease;
    // background: transparent url(../img/arr.png) no-repeat scroll
    &:hover {
      box-shadow: 0 0 15px transparentize(#000,0.6); } }

  .go-right {
    right: 0;
    margin-right: 150px;
    background-position: 100% 0%;
    @media only screen and (max-width: 1050px) {
      margin-right: 50px; }
    @media only screen and (max-width: 768px) {
      display: none; } }

  .go-left {
    margin-left: 150px;
    background-position: 0% 0%;
    @media only screen and (max-width: 1050px) {
      margin-left: 50px; }
    @media only screen and (max-width: 768px) {
      display: none; } }

  ul {
    @include clearfix;
    margin: 0;
    position: relative;

    li {
      @include clearfix;
      height: 100vh;
      margin: 0;
      float: left;
      position: relative;
      display: inline-block;
      margin-right: auto;
      margin-left: auto;
      text-align: center; } }


  .slider-pager {
    position: absolute;
    bottom: 150px;
    @media only screen and (max-width: 1366px) {
      bottom: 105px; }
    @media only screen and (max-width: 768px) {
      bottom: 160px; }
    @media only screen and (max-width: 768px) {
      bottom: 120px; }
    @media only screen and (max-width: 768px) {
      bottom: 90px; }

    li {
      width: 20px;
      height: 20px;
      opacity: 0.75;
      margin-right: 10px;
      margin-left: 10px;

      a {
        width: 8px;
        height: 8px;
        border-color: #fff;

        &:hover,
        &.active {
          background-color: #fff;
          border-color: #fff; } } } }

  .bg_slider {
    // width: 100%
    position: relative;
    height: 100vh;
    box-shadow: inset 0 -2px 4px transparentize($cm-black, 0.8);
    background-color: transparent;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;

    &.fixedBg {
      background-attachment: fixed;
      @media only screen and (max-width: 768px) {
        background-attachment: scroll; } } }

  .slider {
    @include clearfix;
    position: relative;
    padding-top: 30px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (min-width: 1366px) {
      width: 100%; }

    @media only screen and (max-width: 640px) {
      width: 100%; }

    &.content {
      width: 100%;
      float: left;
      // background-color: red
      // padding-top: 50px

      a,
      a:hover {
        color: #fff; }

      .box {
        padding: 25px;
        width: 100%;
        float: none;
        margin-right: auto;
        margin-left: auto;
        min-height: auto;
        min-width: auto;
        max-width: auto;
        width: auto; }

      .title {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 2.8rem;
        @media only screen and (max-width: 640px) {
          font-size: 1.6rem;
          line-height: 2.2rem; } }

      .description {
        font-size: 1.5rem;
        line-height: 1.8rem;
        padding-top: 10px;
        @media only screen and (max-width: 640px) {
          font-size: 1.3rem; } } } }


  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

  .wrapper_buttons {
    position: relative;
    height: 65%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media screen and (max-width: 1366px) {
      height: 60%; }

    @media screen and (max-width: 640px) {
      height: 75%;
      justify-content: center; } }

  .slider_description {
    position: absolute;
    top: 80px;
    right: 20px;
    max-width: 450px;
    @extend .air;
    padding: 35px;
    background-color: rgba(7,118,82,0.8);
    border-radius: 2px;
    font-size: 2.7rem;
    line-height: 3.5rem;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    text-align: left;

    p, h1, h2, h3, h4, h5, h6, span, a {
      margin: 0;
      font-size: 2.7rem;
      line-height: 3.5rem;
      color: #fff;
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      text-align: left;

      @media screen and (max-width: 1366px) {
        font-size: 2.2rem;
        line-height: 2.7rem; } }

    @media screen and (max-width: 640px) {
      font-size: 2.3rem;
      line-height: 2.8rem;
      left: 20px;

      p, h1, h2, h3, h4, h5, h6, span, a {
        font-size: 2.3rem;
        line-height: 2.8rem;
        left: 20px; } } }


  .slider_buttons {

    &__item {
      box-sizing: border-box;
      padding: 10px 10px;
      border-radius: 2px;
      margin-left: 5px;
      font-size: 1.5em;
      color: #fff;
      text-decoration: none;
      box-shadow: 0 1px 2px transparentize(darken($cm-brand-primary, 20%), 0.7);
      transition: background-color 0.2s ease;
      background-color: rgba(7,118,82,0.8);

      &:hover {
        text-decoration: none; }

      &_more {
        margin-left: 20px; }

      &_whatsapp {
        @media screen and (min-width: 641px) {
          display: none; } } } }

  .box-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

  .box {
    position: relative;
    display: block;
    float: right;
    min-height: 400px;
    width: 400px;
    box-sizing: border-box;
    padding: 35px;
    color: $cm-white;
    background-color: transparentize($cm-brand-primary, 0.2);
    // mix-blend-mode: multiply
    @media only screen and (max-width: 767px) {
      width: 100%; }
    @media only screen and (max-width: 640px) {
      min-height: 200px;
      height: 300px; }
    @media only screen and (max-width: 320px) {
      height: 230px;
      padding: 15px; }

    a,
    a:hover {
      color: #fff; } }

  .title {
    margin: 0;
    font-size: 2.9rem;
    font-weight: 300;
    line-height: 4rem;
    text-align: left;
    // @media only screen and (max-width: 1050px)
    // 	font-size: 3.5rem
    @media only screen and (max-width: 768px) {
      font-size: 2.5rem; }
    @media only screen and (max-width: 640px) {
      font-size: 2.5rem;
      line-height: 3rem; }
    @media only screen and (max-width: 360px) {
      font-size: 2rem;
      line-height: 2.5rem; }
    @media only screen and (max-width: 360px) {
      font-size: 1.8rem; }

    p {
      margin: 0;
 } }      // font-size: 2.5em

  .description {
    margin: 0;
    padding-top: 20px;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.5rem;
    @media only screen and (max-width: 640px) {
      font-size: 1.5rem;
      line-height: 1.7rem; }
    @media only screen and (max-width: 320px) {
        font-size: 1.3rem; } }

  .more-info {
    text-transform: uppercase;
    font-size: 14px;
    color: $cm-white;
    padding: 20px 20px;
    &:hover {
      text-decoration: none; }
    &.absolute {
      position: absolute;
      bottom: 0;
      right: 0; } }

  .share-info {
    font-size: 14px;
    color: $cm-white;
    padding: 20px 20px;
    &:hover {
      text-decoration: none; }
    &.absolute {
      position: absolute;
      bottom: 0;
      left: 0; } }

  .share-frame {
    display: none;
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 666;
    left: 0;
    bottom: 47px;
    @extend %clearfix;

    .arrow-down {
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 10%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff; }

    .share-links {
      float: left;
      display: block;
      width: 33.333%;
      text-align: center;
      background-color: #FFF;
      color: gray;
      font-size: 1.1rem;
      padding-top: 20px;
      padding-bottom: 20px;

      &:hover {
        color: $cm-col-primary;
        background-color: #ededed; }

      &:nth-child(1) {
        border-radius: 3px 0 0 3px; }
      &:nth-child(3) {
        border-radius: 0 3px 3px 0; } } }




  // Paginas internas
  // .cont-moneda
  //// background-image: url(../img/interna/moneda.jpg)

  // .bg_empresa
 }  // 	background-image: url(../img/backgrounds/bank.jpg)
