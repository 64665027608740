.content_module {
	// background-color: transparentize(red, 0.5)
	border-top: 1px solid lightgray;
	padding-top: 20px;
	margin-top: 20px;
	margin-bottom: 20px;

	&.content_results {
		border-top: none;
		margin-bottom: 110px;

		.result {
			display: none;
			padding-bottom: 5px;
			border-bottom: 1px solid lightgray;

			&.active {
				display: block; }

			&.empty {
				display: none;
				border-bottom: none;
				padding-top: 30px;
				padding-bottom: 30px;
				color: gray;
				text-align: center;
				font-size: 20px;

				&.active {
					display: block; } } } }

	.module_menu {
		margin-bottom: 0px;
		text-transform: uppercase;
		// border: 1px solid lightgray
		// border-radius: 5px

		h1, h2, h3, h4, h5 {
			color: #333; }

		a {
			// color: #333
			color: $cm-col-secondary;
			margin: 0;

			&:hover {
				color: $cm-col-secondary; } }

		.title {
			padding: 0;
			margin-bottom: 10px; } }

	h1 {
		margin: 0;
		font-family: 'Raleway', sans-serif;
		color: $cm-black; }

	h2, h3, h4, h5 {
		color: $cm-col-primary; }

	h3 {
		display: block;
		margin-bottom: 10px; }

	h4, h5, h6 {
		margin-bottom: 5px; }

	a {
		color: $cm-col-primary;
		&:visited {
			color: $cm-col-primary; }
		&:hover {
			color: darken($cm-col-primary, 20%); } }

	img {
		margin-bottom: 25px;
		max-width: 100%; }

	.upup {
		display: inline-block;
		text-decoration: none;
		color: lightgray;
		padding: 3px 6px;
		border: 1px solid lightgray;
		border-radius: 3px;
		margin-top: 20px;
		margin-bottom: 20px;

		&:hover {
			text-decoration: none;
			border-color: gray;
			color: gray; }

		&.sm {
			font-size: 0.8em;
			padding: 0;
			border: none;
			margin-bottom: 0; } }


	h2 {
		display: block;
		margin-bottom: 25px;
		text-transform: uppercase;
		// padding-top: 20px
		// border-top: 1px solid lightgray
		color: $cm-col-secondary;
		text-decoration: none;
		a, i {
			color: $cm-col-secondary;
			text-decoration: none; }
		&:hover {
			a, i {
				text-decoration: none;
				color: darken($cm-col-secondary, 20%); } }
		i {
			margin-right: 10px;
			padding-right: 4px;
			padding-left: 4px;
			border-radius: 50px;
			background-color: red; }

		&.main {
			color: #000; } }

	p {
		margin-bottom: 20px;
		line-height: 1.5rem;

		&.sm {
			font-size: 0.75rem;
			line-height: 1.1rem;
			color: darken($cm-white, 30%);
			strong {
				font-weight: 400; } }

		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline; } }

		strong {
			color: $cm-col-primary;
			font-weight: 700; } }

	.list {
		margin: 0;
		padding: 0;
		margin-bottom: 40px;
		.banks_logo {
			display: block;
			margin-top: 5px; }
		li {
			padding-top: 5px;
			padding-bottom: 5px;
			line-height: 1.2em; }
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline; } }

		&.bullet li {
			list-style-type: circle;
			margin-left: 15px; }
		&.disc li {
			list-style-type: disc; }
		&.square li {
			font-weight: 700;
			list-style-type: square; }
		&.grid li {
			float: left;
			width: 50%;
			@media screen and (max-width: 640px) {
				width: 100%; } }
		&.accent {
			// margin: 0
			box-sizing: border-box;
			padding: 40px;
			color: $cm-white;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#70c6ab+0,46a6c3+100&0.8+0,0.8+100 */
			/* IE9 SVG, needs conditional override of 'filter' to 'none' */
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjNzBjNmFiIiBzdG9wLW9wYWNpdHk9IjAuOCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNDZhNmMzIiBzdG9wLW9wYWNpdHk9IjAuOCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
			background: -moz-linear-gradient(45deg, rgba(112,198,171,1) 0%, rgba(70,166,195,1) 100%); /* FF3.6+ */;
			background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(112,198,171,1)), color-stop(100%,rgba(70,166,195,1))); /* Chrome,Safari4+ */;
			background: -webkit-linear-gradient(45deg, rgba(112,198,171,1) 0%,rgba(70,166,195,1) 100%); /* Chrome10+,Safari5.1+ */;
			background: -o-linear-gradient(45deg, rgba(112,198,171,1) 0%,rgba(70,166,195,1) 100%); /* Opera 11.10+ */;
			background: -ms-linear-gradient(45deg, rgba(112,198,171,1) 0%,rgba(70,166,195,1) 100%); /* IE10+ */;
			background: linear-gradient(45deg, rgba(112,198,171,1) 0%,rgba(70,166,195,1) 100%); /* W3C */;
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc70c6ab', endColorstr='#cc46a6c3',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */;
			h1, h2, h3, h4, h5, h6 {
				color: #fff; }
			li {
				padding-top: 10px;
				padding-bottom: 10px;
				list-style-type: disc;
				margin-left: 15px;
				a {
					text-decoration: none;
					color: #fff;
					&:hover {
						text-decoration: underline; } } }

			@media screen and (max-width: 768px) {
				padding: 20px; } }

		li {
			line-height: 1rem; } } }

.editable--trigger {
	display: none; }

.read_done {
	margin-bottom: 80px;
	&.sm {
		margin-bottom: 0; }
	a {
		color: #fff !important;
		text-decoration: none;
		font-weight: 700 !important;
		&:hover {
			text-decoration: none; }

		i {
			margin-left: 5px;
			margin-right: 5px; } } }

.download_table {
	.files {
		border: 1px solid lightgray;
		margin-bottom: 30px;
		// background-color: red
		.head {
			margin: 0;
			display: block;
			background-color: #f5f5f5;
			padding-top: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid lightgray;

			&.main {
			 	background-color: $cm-col-secondary;
			 	color: #fff;
			 	font-weight: 700; }

			&.secondary {
				background-color: #e5e5e5; }

			.download {
				float: right;
				i {
					margin-right: 5px; } } }


		ul {
			margin: 0;
			padding-top: 0;
			padding-bottom: 0; }
		li {
			// background-color: yellow
			padding-top: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid lightgray;
			@extend %clearfix;

			&:last-child {
				border-bottom: none; }

			strong {
				text-transform: uppercase;
				margin-right: 5px; }

			span {
				color: gray; }

			span, strong {
				display: inline-block;

				@media screen and (max-width:640px) {
					display: block;
					margin-bottom: 5px; } }

			.download {
				display: inline-block;
				float: right;

				i {
					margin-right: 5px; }

				@media screen and (max-width:640px) {
					display: block;
					float: left;
					margin-top: 5px; } } } } }

.search_services {
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	padding: 3px;
	text-align: center;

	span {
		@extend %clearfix;
		display: block;
		text-align: left;
		margin-bottom: 10px;
 }		// float: left

	.entidad {
		@extend %clearfix;
		float: left;
		margin-top: 20px;
		width: 50%;
		margin-bottom: 15px;
		@media screen and (max-width:768px) {
			width: 100%; } }

	.listado_sucursales {
		@extend %clearfix;
		display: inline-block;
		text-align: right;
		float: right;
		margin-right: 20px;
		margin-top: 15px;
		// width: 50%
		margin-bottom: 15px;
		@media screen and (max-width:640px) {
			display: none; } }

	.icono_busqueda {
		display: inline-block;
		@media screen and (max-width:640px) {
			margin-right: 10px; } }
	.texto_busqueda {
		display: none;
		font-size: 0.9rem;
		@media screen and (max-width:640px) {
			display: inline-block; } }

	#search_services {
		display: block;
		float: left;

		width: 100%;
		margin-right: 1%;
		@media screen and (max-width: 768px) {
			width: 100%; }
		.autocomplete-container-7a26d {
			min-height: 45px; } }

	.field {
		width: auto;
		float: left;

		&.services,
		&.banks {
			width: 50%;
			margin-right: 1%;
			height: 45px;
			@media screen and (max-width: 768px) {
				width: 100%; } }

		&.banks {
			cursor: pointer; } }

	.cta {
		height: 45px;
		width: 10%;
		margin: 0;
		float: right;
		color: #fff;
		@media screen and (max-width:640px) {
			width: 100%;
			float: none; }
		&:hover {
			color: #fff; } } }

.result_services {
	display: none;
	&.active {
		display: block; } }

.info_mobile {
	display: none;
	margin-right: auto;
	margin-left: auto;
	@media screen and (max-width: 768px) {
		display: block; } }


.sucursales {
	width: 100%;
	@media screen and (max-width: 768px) {
		width: 100%;
		overflow-x: scroll; }

	.demo {
		width: 100%;
		border: 1px solid #C0C0C0;
		border-collapse: collapse;
		padding: 5px;

		th {
			border: 1px solid #C0C0C0;
			padding: 5px;
			background: #F0F0F0;
			font-size: 14px; }

		td {
			border: 1px solid #C0C0C0;
			padding: 5px;
			background: #FFFFFF;
			font-size: 13px; } } }



.results_search {
	button {
		&[type="submit"] {
			width: 10%;
			margin: 0;
			float: right;
			@media only screen and (max-width: 640px) {
				width: 20%; } } }
	input {
		&[type="submit"] {
			width: 20%;
			margin: 0;
			float: right;
			@media only screen and (max-width: 640px) {
				width: 70%; } }

		&[type="text"] {
			width: 85%;
			float: left;
			@media only screen and (max-width: 640px) {
				width: 75%; } } }

	.field {
		font-size: 14px; } }

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0; }

ul.pagination li {
	display: inline; }

ul.pagination li a {
    color: black;
    float: left;
    padding: 5px 12px;
    margin: 5px;
    text-decoration: none;
    transition: background-color .3s;
    border-radius: 0.15rem;
 }    // border: 1px solid #ddd

ul.pagination li a.active {
    background-color: $cm-col-primary;
    color: white;
 }    // border: 1px solid $cm-col-primary

ul.pagination li a:hover:not(.active) {
	background-color: #ddd; }

div.center {
	text-align: center;
	@extend %clearfix;
	margin-bottom: 150px; }


