// @import stickyfooter
@import "clearfix";
@import "buttons";
// http://semantic.gs/
@import "semantic-grid";

body {
 }	// padding-bottom: 80px

input, select, textarea, button {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none; }

.wrapper {
	@include clearfix;
	position: relative;
	@include box-sizing(border-box);
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto;
	&.xl {
		max-width: 1600px; }
	&.lg {
		max-width: 1200px; }
	&.md {
		max-width: 1024px; }
	&.sm {
		max-width: 800px; }
	&.xs {
		max-width: 500px; } }

textarea {
	font-size: 1em;
	font-family: 'Montserrat', sans-serif;
	resize: none; }

input {
	font-size: 1em; }

.minHFull {
	min-height: 100vh;
	border-bottom: 1px solid darken($cm-white, 10%); }

.block-titles {
	margin-top: 0;
	margin-bottom: 0;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 2.2rem;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 20px; }

.block-header {
	padding-top: 70px;
	padding-bottom: 20px; }

.shadow_in {
	box-shadow: inset 0 -2px 4px transparentize($cm-black, 0.8); }

.shadow_out {
	box-shadow: 0 2px 4px transparentize($cm-black, 0.8); }

.lightbox {
	display: none;
	> div {
		display: block; } }

.divisor {
	display: block;
	&.line {
		height: 1px;
		margin-top: 20px;
		margin-bottom: 20px;
		background-color: darken($cm-white, 20%); }
	&.lg {
		padding-top: 50px;
		padding-bottom: 50px; } }

.air {
	@include box-sizing(border-box);
	padding: 20px; }

%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both; } }
