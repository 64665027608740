.cajeros {
	min-height: 100vh;
	background-color: $cm-col-secondary;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	background-blend-mode: multiply;

	.block-titles {
		padding-top: 50px;
		color: $cm-white; }

	&--container {
		background-color: transparentize($cm-white, 0.05);
		border-radius: 5px;

		.cta {
			margin: 0; }

		h3 {
			text-align: left;
			color: lighten($cm-black, 20%);
			font-size: 1.3rem;
			margin-bottom: 3px;
			strong {
				color: $cm-col-secondary; }

			@media screen and (max-width: 640px) {
				font-size: 1rem; } }

		p {
			text-align: center;
			font-weight: 700;
			color: lighten($cm-black, 20%);
			padding-bottom: 15px;
			strong {
				font-weight: 400;
				color: lighten($cm-black, 50%); } } }

	&--content {
		@include clearfix;
		margin-top: 20px;
		> div {
			float: left; }
		.map {
			width: 70%;
			max-height: 550px;
			height: 550px;

			@media screen and (max-width: 640px) {
				width: 100%;
				height: 380px;
				margin-bottom: 30px;

				&.air {
					padding: 3px; } } }
		#map {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			background-color: #e6e6e6; }
		.cajero {
			width: 30%;
			max-height: 550px;
			height: 550px;
			// box-shadow: inset 0 0 0 1px #000

			@media screen and (max-width: 640px) {
				width: 100%; } }
		.references {
			width: 100%;
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid darken($cm-white, 15%);

			&--box {
				width: 33.333%;
				float: left;
				text-align: center;

				img {
					height: 40px;
					@media screen and (max-width: 640px) {
						margin-bottom: 20px; } }

				span {
					display: inline-block;
					vertical-align: middle;
					margin-right: auto;
					margin-left: auto;
					font-size: 0.85rem;

					@media screen and (max-width: 640px) {
						font-size: 0.60rem; }


					i {
						vertical-align: middle;
						display: inline-block;
						width: 68px;
						height: 47px;
						margin-right: 15px; }
					.ref1 {
						background-image: url("../img/marker-1.png"); }
					.ref2 {
						background-image: url("../img/marker-2.png"); }
					.ref3 {
						background-image: url("../img/marker-3.png"); } } } }

		.air {
			@include clearfix;
			padding: 10px; }

		ul {
			padding: 0;
			margin: 0; }

		li {
			position: relative;
			height: 50px;
			border-radius: 3px;

			&:hover {
				box-shadow: 0 0 8px transparentize($cm-black, 0.6);
				i {
					color: $cm-col-primary; } }

			a {
				float: left;
				margin: 0;
				width: 80%;
				font-size: 0.7rem;
				font-weight: 400;
				text-align: left;
				color: #000;
				span {
					display: block;
					padding-top: 3px; } }
			img {
				float: left;
				height: 30px;
				margin-right: 10px; }
			i {
				position: absolute;
				right: 15px;
				top: 15px;
				font-size: 1.2rem;
				color: darken($cm-white, 30%); } } } }
