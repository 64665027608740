.buscador {
  min-height: 100vh;
  // background-color: $cm-col-secondary
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-blend-mode: multiply;
  background-image: url('img/visaplus.png');

  display: flex;
  justify-content: center;
  align-items: center;

  .block-titles {
    padding-top: 50px;
    color: $cm-white; }

  &--container {
    background-color: transparentize($cm-white, 0.05);
    border-radius: 5px;

    h3 {
      text-align: center;
      color: lighten($cm-black, 20%);
      font-size: 1.6rem;
      margin-bottom: 3px;
      strong {
        color: $cm-col-secondary; }

      @media screen and (max-width: 640px) {
        font-size: 1.4rem; } }
    p {
      text-align: center;
      font-weight: 700;
      color: lighten($cm-black, 20%);
      padding-bottom: 15px;
      strong {
        font-weight: 400;
        color: lighten($cm-black, 50%); } }

    select {
      background-color: #FFF; } }

  &--checks {
    @include clearfix; }

  &--place {
    @include clearfix;
    .aps {
      display: block;
      padding-bottom: 20px;

      @media screen and (max-width: 768px) {
        margin-bottom: 25px; } }

    .label-description {
      display: block;
      width: 100%;
      font-size: 0.8rem;
      margin-bottom: 5px; }
    .mid {
      float: left;
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%; } } }

  .action-search {
    text-align: center; }

  input[type=radio],
  input[type=checkbox] {
    display: none; }
  input[type=checkbox] + label {
    cursor: pointer;
    display: block;
    float: left;
    // width: 33.333%
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;

    @media screen and (max-width: 768px) {
      width: 100%; } }

  input[type=checkbox] + label:before {
    font-size: 15px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f096"  /* unchecked icon */;
    letter-spacing: 6px  /* space between checkbox and label */; }
  input[type=checkbox]:checked + label:before {
    content: "\f14a"  /* checked icon */;
    color: $cm-col-primary;
    letter-spacing: 5px  /* allow space for check mark */; }

  input[type=radio] + label {
    cursor: pointer;
    display: block;
    float: left;
    // width: 33.333%
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px; }
  input[type=radio] + label:before {
    font-size: 15px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f10c"  /* unchecked icon */;
    letter-spacing: 5px  /* space between checkbox and label */; }
  input[type=radio]:checked + label:before {
    content: "\f058"  /* checked icon */;
    color: $cm-col-primary;
    letter-spacing: 5px  /* allow space for check mark */; }


  /* ================================== */
  /*                2017                */
  /* ================================== */

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    @media screen and (max-width: 640px) {
      margin-top: 80px;
      margin-bottom: 80px; } }


  &__box {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    width: 100%; }

  &__boxContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 640px) {
      flex-direction: column; } }

  &__title {
    color: #fff;
    text-align: center;
    font-size: 30px;

    @media screen and (max-width: 640px) {
      font-size: 20px; } }

  &__item {
    box-sizing: border-box;
    background-color: white;
    padding: 30px 90px;
    margin: 10px;
    width: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    flex-direction: column;
    text-align: center;



    &:hover {
      text-decoration: none; }

    img {
      height: 100px; }

    span {
      margin-top: 20px;
      color: gray; } } }
