nav {
	@include clearfix;
	position: fixed;
	z-index: 1000;
	width: 100%; }

.nav-main {
	position: fixed;
	width: 100%;
	z-index: 999;
	background-color: transparentize($cm-white, 0.03);
	box-shadow: 0 0 3px transparentize($cm-black, 0.7); }

.nav-menu {
	@include clearfix;
	position: relative;
	margin: 0;
	display: block;
	width: 100%;
	height: auto;
	@media only screen and (max-width: 768px) {
		position: fixed;
		height: 100vh;
		// height: 100%
		// max-height: 90%
		display: none;
		overflow-y: scroll;
		top: 55px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #FFF;
 } }		// margin-bottom: 150px

.logo {
	position: absolute;
	display: block;
	width: 90px;
	transition: all 0.2s ease 0.1s;
	top: 12px;
	left: 10px;
	@media only screen and (max-width: 768px) {
		width: 40px;
		top: 7px; }

	&.is-small {
		width: 40px;
		top: 7px; }

	img {
		z-index: 10000;
		width: auto; } }

.toggle-nav-container {
	@include clearfix;
	display: block;
	border-bottom: 1px solid darken($cm-white, 10%);
	// background-color: green
	.toggle-nav {
		display: none;
		float: right;
		a {
			color: $cm-brand-primary;
			text-transform: uppercase;

			display: block;
			font-size: 1.5rem;
			padding: 13px 20px;
			padding-right: 10px;
			text-decoration: none;
			&:hover, &:active {
				color: $cm-col-secondary;
				text-decoration: none; } }

		@media only screen and (max-width: 768px) {
			display: block; } } }

.nav-links {
	position: relative;
	margin: 0;
	text-transform: uppercase;
	float: right;
	font-size: 0.7rem;
	@media only screen and (max-width: 768px) {
		font-size: 0.6rem;
		display: block;
		width: 100%; }

	&.active {
		box-shadow: inset 0 5px 4px transparentize($cm-black, 0.8);
		@media only screen and (max-width: 768px) {
			box-shadow: none; }
		> a {
			color: $cm-col-primary; }

		.nav-links--linegradient {
			height: 5px; } }

	.full_w_item {
		width: 100%;

		button {
			&[type="submit"] {
				width: 10%;
				margin: 0;
				float: right;
				@media only screen and (max-width: 640px) {
					width: 20%; } } }
		input {
			&[type="submit"] {
				width: 20%;
				margin: 0;
				float: right;
				@media only screen and (max-width: 640px) {
					width: 70%; } }

			&[type="text"] {
				width: 85%;
				float: left;
				@media only screen and (max-width: 640px) {
					width: 75%; } } } }

	.field {
		font-size: 14px; }

	&--primary {}


	&--linegradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		transition: height 0.2s ease 0s; }

	> a {
		display: inline-block;
		text-decoration: none;
		color: lighten($cm-black, 30%);
		font-family: 'Montserrat', sans-serif;
		transition: all 0.2s ease 0s;
		@media only screen and (max-width: 768px) {
			display: block;
			border-top: 1px solid darken($cm-white, 5%); }

		i {
			float: right;
			display: none;
			@media only screen and (max-width: 768px) {
				display: inline-block; }

			&.is_visible {
				display: block;
				margin-left: 8px; } }

		.text {
			display: inline-block;
			@media only screen and (max-width: 768px) {
 } }				// display: none

		.text-sm {
			display: none;
			text-decoration: none;
			@media only screen and (max-width: 768px) {
 } }				// display: inline-block

		&.nav-links--big {
			transition: all 0.2s ease-out 0.1s;
			padding: 45px 15px 20px 15px; }

		&.nav-links--small {
			padding: 20px 15px 20px 15px;
			@media only screen and (max-width: 768px) {
				padding: 20px 15px 20px 15px;
 } } }				// padding: 20px 15px 20px 15px

	&:hover {
		box-shadow: inset 0 5px 4px transparentize($cm-black, 0.8);
		@media only screen and (max-width: 768px) {
			box-shadow: none; }
		> a {
			color: $cm-col-primary; }

		.nav-links--linegradient {
			height: 5px; } } }

.nav-dropdown {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: transparentize($cm-white, 0.03);
	box-shadow: 0 5px 3px transparentize($cm-black, 0.9);
	border-top: 1px solid darken($cm-white, 8%);
	@media only screen and (max-width: 768px) {
		position: relative;
		// box-shadow: none
		background-color: darken($cm-white, 7%);
		box-shadow: none; }


	.nav-dropdown--linegradient {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3px;
		@media only screen and (max-width: 768px) {
			display: none; } }

	ul {
		position: relative;
		// display: none
		// padding: 20px 0 20px 0
		margin-bottom: 0;
		padding: 20px;
		max-height: 80%;
		@media only screen and (max-width: 768px) {
			padding: 10px; } }

	.return {
		position: absolute;
		width: 100%;
		bottom: 0; }

	li {
		float: left;
		width: 33.333%;
		margin: 0;
		padding: 0;
		@media only screen and (max-width: 768px) {
			width: 50%; }
		@media only screen and (max-width: 480px) {
			width: 100%; }

		a {
			color: lighten($cm-black, 50%);
			display: block;
			padding: 5px 10px;
			box-sizing: border-box;
			font-size: 12px;
			@media only screen and (max-width: 768px) {
				padding: 10px 10px;
				&:hover {
					background-color: #fff;
					border-radius: 5px;
					box-shadow: 0 2px 5px transparentize($cm-black, 0.9); } }

			&:hover {
				text-decoration: none;
				color: $cm-col-primary;
				i {
					opacity: 1; } }

			i {
				opacity: 0;
				transition: all 0.2s ease 0s;
				margin-left: 5px;
				color: $cm-col-primary;

				@media only screen and (max-width: 768px) {
					display: none; } } } } }


